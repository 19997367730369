import React, { useEffect } from "react";
import Node from "./img/pngegg.png";
import ReactLogo from "./img/react.png";
import JavaScriptLogo from "./img/javascript.png";
import psLogo from "./img/ps.png";
import lrLogo from "./img/lr.png";
import javalogo from "./img/java.png";
import phplogo from "./img/php.png";
import androidLogo from "./img/android.png";
import xdLogo from "./img/xd.png";
import aeLogo from "./img/ae.png";
import smf from "./img/smf.png";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    flexDirection: "column",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    justifyItems: "center",
    maxHeight: "200px",
  },
  imgSkills: {
    objectFit: "contain",
    width: "80%",
  },
  desc: {
    width: "100%",
  },
}));
function Skills() {
  const classes = useStyles();
  useEffect(() => {
    const skillsContent = document.getElementsByClassName("skills__content"),
      skillsHeader = document.querySelectorAll(".skills__header");

    /*==ACCORDION SKILLS==*/
    function toggleSkills() {
      let itemClass = this.parentNode.className;

      for (let i = 0; i < skillsContent.length; i++) {
        skillsContent[i].className = "skills__content skills__close";
      }
      if (itemClass === "skills__content skills__close") {
        this.parentNode.className = "skills__content skills__open";
      }
    }
    skillsHeader.forEach((el) => {
      el.addEventListener("click", toggleSkills);
    });
  }, []);

  return (
    <div>
      <section className="skills section" id="skills">
        <h2 className="section__title">Skills</h2>
        <span className="section__subtitle">My technical level</span>
        <div className="container grid">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}>Symfony</h4>
                <img className={classes.imgSkills} src={smf} alt="" />
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}>JavaScript</h4>

                <img
                  className={classes.imgSkills}
                  src={JavaScriptLogo}
                  alt=""
                />
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}>ReactJs / Native</h4>

                <img className={classes.imgSkills} src={ReactLogo} alt="" />
              </Paper>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}>NodeJs</h4>

                <img className={classes.imgSkills} src={Node} alt="" />
              </Paper>
            </Grid>
          </Grid>

          <span className="section__subtitle2">Technologies</span>

          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}>Java</h4>

                <img className={classes.imgSkills} src={javalogo} alt="" />
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}>PHP</h4>

                <img className={classes.imgSkills} src={phplogo} alt="" />
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}>Android</h4>

                <img className={classes.imgSkills} src={androidLogo} alt="" />
              </Paper>
            </Grid>

            {/* <Grid item xs={6} sm={3}>
              <Paper className={classes.paper}>
                <h4 className={classes.desc}>Photoshop</h4>

                <img className={classes.imgSkills} src={psLogo} alt="" />
              </Paper>
            </Grid> */}
          </Grid>

          <span className="section__subtitle2">Editing</span>

          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}> XD </h4>
                <img className={classes.imgSkills} src={xdLogo} alt="" />
              </Paper>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}> Lightroom</h4>

                <img className={classes.imgSkills} src={lrLogo} alt="" />
              </Paper>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}>Photoshop</h4>

                <img className={classes.imgSkills} src={psLogo} alt="" />
              </Paper>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Paper className={`${classes.paper} services__content`}>
                <h4 className={classes.desc}>After Effects</h4>
                <img className={classes.imgSkills} src={aeLogo} alt="" />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </section>
    </div>
  );
}

export default Skills;
