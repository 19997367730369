import React from "react";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer__bg">
          <div className="footer__container container grid">
            <div>
              <h1 className="footer__title">Sitraka</h1>
              <span className="footer__subtitle"> Web App Developer</span>
            </div>
            <ul className="footer__links">
              <li>
                {/* <a href="#services" className="footer__link">
                  Services
                </a> */}
                <a href="#portfolio" className="footer__link">
                  Portfolio
                </a>
                <a href="#contact" className="footer__link">
                  Contact Me
                </a>
              </li>
            </ul>
            <div className="footer__socials">
              <a
                href="https://www.facebook.com/herinambinina.andrianarisoa"
                className="footer__social"
                target="_blank"
              >
                <i class="uil uil-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/nambininaa"
                className="footer__social"
                target="_blank"
              >
                <i class="uil uil-instagram"></i>
              </a>
              <a
                href="https://twitter.com/asitrakah"
                className="footer__social"
                target="_blank"
              >
                <i class="uil uil-twitter-alt"></i>
              </a>
            </div>
          </div>
          <p className="footer__copy">&#169; Akartis . All right reserved</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
