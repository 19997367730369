import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper/core";

import amazon from "../../asset/img/amazon.JPG";
import ecom from "../../asset/img/e-commerce.JPG";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);
function Portfolio() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  return (
    <>
      <section className="portfolio section" id="portfolio">
        <h2 className="section__title">Portfolio</h2>
        <span className="section__subtitle"> Most recent work</span>

        <Swiper
          cssMode={true}
          navigation={{
            // Both prevEl & nextEl are null at render so this does not work
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onSwiper={(swiper) => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;

              // Re-init navigation
              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          className="portfolio__container container"
        >
          {/* PORTOLIO 1 */}
          <SwiperSlide className="portfolio__content grid ">
            <img className="portfolio__img" src={amazon} alt="" />

            <div className="portfolio__data">
              <h3 className="portfolio__title">Amazon Clone</h3>
              <p className="portfolio__description">Developed with react js</p>
              <a
                target="_blank"
                href="https://clone-8b354.firebaseapp.com"
                className="button button--flex button--small portfolio__button"
              >
                Demo
                <i class="uil uil-arrow-right button__icon"></i>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="portfolio__content grid ">
            {/* PORTOLIO 2 */}

            <img className="portfolio__img" src={ecom} alt="" />

            <div className="portfolio__data">
              <h3 className="portfolio__title">E-commerce Website</h3>
              <p className="portfolio__description">Developed with react js</p>
              <a
                target="_blank"
                href="https://crwn-db-c580b.web.app/"
                className="button button--flex button--small portfolio__button"
              >
                Demo
                <i class="uil uil-arrow-right button__icon"></i>
              </a>
            </div>
          </SwiperSlide>
          <div class="swiper-button-prev" ref={navigationPrevRef}>
            <i class="uil uil-angle-left-b swiper-portfolio-icon"></i>
          </div>
          <div class="swiper-button-next" ref={navigationNextRef}>
            <i class="uil uil-angle-right-b swiper-portfolio-icon"></i>
          </div>
        </Swiper>
      </section>
    </>
  );
}

export default Portfolio;
