import React from "react";
import me from "../about/me2.jpg";
function Projetc() {
  return (
    <div>
      <section className="project section">
        <div className="project__bg">
          <div className="project__container container grid">
            <div className="project__data">
              <h2 className="project__title"> You have a New Project </h2>
              <p className="project__description">
                {" "}
                Contact ME if you have an IDEA
              </p>
              <a href="#contact" className="button button--flex button--white">
                Contact ME
                <i className="uil uil-message project__icon button__icon"></i>
              </a>
            </div>

            <img src={me} alt="" className="project__img" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Projetc;
