import React, { useEffect } from "react";

function Header() {
  var navMenu = document.getElementById("nav-menu"),
    navToggle = document.getElementById("nav-toggle"),
    navClose = document.getElementById("nav-close");

  /*Menu show*/
  /* validate if oconstant exists*/
  const handleToogle = () => {
    //const navMenu = document.getElementById("nav-menu");
    document.getElementById("nav-menu").classList.add("show-menu");
  };

  const handleClose = () => {
    // const navMenu = document.getElementById("nav-menu");
    document.getElementById("nav-menu").classList.remove("show-menu");
  };

  /*Remove Menu mobile*/

  const navLink = document.querySelectorAll(".nav__link");
  function linkAction() {
    const navMenu = document.getElementById("nav-menu");
    // When we click on each nav__link, we remove the show-menu class
    navMenu.classList.remove("show-menu");
  }

  navLink.forEach((n) => n.addEventListener("click", linkAction));

  useEffect(() => {
    const sections = document.querySelectorAll("section");
    const navLi = document.querySelectorAll("nav .nav__menu ul li a");
    window.addEventListener("scroll", () => {
      let current = "";
      sections.forEach((s) => {
        const sectionTop = s.offsetTop;
        const sectionHeight = s.clientHeight;

        if (window.scrollY >= sectionTop - sectionHeight / 3) {
          current = s.getAttribute("id");
        }
      });

      navLi.forEach((li) => {
        li.classList.remove("active-link");
        if (li.classList.contains(current)) {
          li.classList.add("active-link");
        }
      });
    });
  });
  return (
    <div className="header" id="header">
      <nav className="nav container">
        <a href="#" className="nav__logo">
          Sitraka
        </a>
        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list grid">
            <li className="nav__item  ">
              <a href="#home" className="nav__link home active-link">
                <i className="uil uil-estate nav__icon"></i> Home
              </a>
            </li>

            <li className="nav__item ">
              <a href="#about" className="nav__link about">
                <i className="uil uil-user nav__icon"></i> About
              </a>
            </li>

            <li className="nav__item ">
              <a href="#skills" className="nav__link skills">
                <i className="uil uil-file-alt nav__icon"></i> Skills
              </a>
            </li>

            {/* <li className="nav__item">
              <a href="#services" className="nav__link">
                <i className="uil uil-briefcase-alt nav__icon"></i> Services
              </a>
            </li> */}

            {/* <li className="nav__item">
              <a href="#portfolio" className="nav__link">
                <i className="uil uil-scenery nav__icon"></i> Portfolio
              </a>
            </li> */}

            <li className="nav__item ">
              <a href="#contact" className="nav__link contact">
                <i className="uil uil-message nav__icon"></i> Contact Me
              </a>
            </li>
          </ul>
          <i
            class="uil uil-times nav__close"
            id="nav-close"
            onClick={handleClose}
          ></i>
        </div>

        <div className="nav__btns">
          {/* THEME change button */}
          <i class="uil uil-moon change-theme" id="theme-button"></i>
          <div className="nav__toggle" id="nav-toggle" onClick={handleToogle}>
            <i className="uil uil-apps"></i>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
