import React from "react";
import me from "./zaio.png";
function Main() {
  return (
    <main className="main">
      <section className="home section" id="home">
        <div className="home__container container grid">
          <div className="home__content grid">
            <div className="home__social">
              <a
                href="https://www.linkedin.com/in/sitraka-andrianarisoa"
                className="home__social-icon"
                target="_blank"
              >
                <i class="uil uil-linkedin-alt"></i>
              </a>
              <a
                href="https://www.facebook.com/herinambinina.andrianarisoa"
                className="home__social-icon"
                target="_blank"
              >
                <i class="uil uil-facebook-f"></i>
              </a>
              <a
                href="https://www.instagram.com/nambininaa"
                className="home__social-icon"
                target="_blank"
              >
                <i class="uil uil-instagram"></i>
              </a>
            </div>
            <div className="home__img">
              <div className="home__blob">
                <img
                  src={me}
                  alt=""
                  className="home__blob-img"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="home__data">
              <h1 className="home__title">Hi, I'am Sitraka</h1>
              <h3 className="home__subtitle">A Full Stack Developer</h3>
              <p className="home__description">
                High Level experience in web development
              </p>
              <a href="#contact" className="button button--flex">
                Contact Me <i class="uil uil-message button__icon"></i>
              </a>
            </div>
          </div>
          <div className="home__scroll">
            <a href="" className="home__scroll-button button--flex">
              <i class="uil uil-mouse-alt home__scroll-mouse"></i>
              <span className="home__scroll-name">Scroll down</span>
              <i class="uil uil-arrow-down home__scroll-arrow"></i>
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Main;
