import React, { useEffect } from "react";

function Qualification() {
  useEffect(() => {
    const tabs = document.querySelectorAll("[data-target]"),
      tabContents = document.querySelectorAll("[data-content]");

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        const target = document.querySelector(tab.dataset.target);
        tabContents.forEach((tabContent) => {
          tabContent.classList.remove("qualification__active");
        });
        target.classList.add("qualification__active");

        tabs.forEach((tab) => {
          tab.classList.remove("qualification__active");
        });

        tab.classList.add("qualification__active");
      });
    });
  }, []);
  return (
    <div>
      <section className="qualification section" id="qualification">
        <h2 className="section__title">Qualification</h2>
        <span className="section__subtitle">My personal journey</span>

        <div className="qualification__container container">
          <div className="qualification__tabs">
            <div
              className="qualification__button button--flex qualification__active"
              data-target="#education"
            >
              <i class="uil uil-graduation-cap qualification__icon"></i>
              Education
            </div>
            <div
              className="qualification__button button--flex"
              data-target="#work"
            >
              <i class="uil uil-briefcase-alt qualification__icon"></i>
              Work
            </div>
          </div>

          <div className="qualification__sections">
            {/* QUALIFICATION CONTENT 1 */}
            <div
              className="qualification__content qualification__active"
              data-content
              id="education"
            >
              {/* QUALIFICATION 1 */}

              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">Baccalauréat</h3>
                  <span className="qualification__subtitle">
                    College Saint Michel - Amparibe Antananarivo
                  </span>
                  <div className="qualification__calendar">
                    <i class="uil uil-calendar-alt"></i>
                    2000-2013
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>

              {/* QUALIFICATION 2 */}

              <div className="qualification__data">
                <div></div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
                <div>
                  <h3 className="qualification__title">
                    Bsc (Hons) IT (major in Software Engineering)
                  </h3>
                  <span className="qualification__subtitle">
                    Universiti Utara Malaysia
                  </span>
                  <div className="qualification__calendar">
                    <i class="uil uil-calendar-alt"></i>
                    2016-2020
                  </div>
                </div>
              </div>
            </div>

            {/* QUALIFICATION CONTENT 2 */}
            <div className="qualification__content" data-content id="work">
              {/* QUALIFICATION 1 */}

              <div className="qualification__data">
                <div>
                  <h3 className="qualification__title">Web Developer Intern</h3>
                  <span className="qualification__subtitle">
                    Flow Corporation - Mauritus
                  </span>
                  <div className="qualification__calendar">
                    <i class="uil uil-calendar-alt"></i>
                    Jul 2019 - Dec 2019
                  </div>
                </div>
                <div>
                  <span className="qualification__rounder"></span>
                  <span className="qualification__line"></span>
                </div>
              </div>

              {/* QUALIFICATION 4 */}
              <div className="qualification__data">
                <div></div>

                <div>
                  {/* <span className="qualification__rounder"></span> */}
                  {/* <span className="qualification__line"></span> */}
                </div>

                <div>
                  <h3 className="qualification__title">
                    Software Developer
                  </h3>
                  <span className="qualification__subtitle">
                    Flow Corporation - Mauritius
                  </span>
                  <div className="qualification__calendar">
                    <i class="uil uil-calendar-alt"></i>
                    Jan 2020 - Present
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Qualification;
