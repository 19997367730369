import React, { useState } from "react";
import me from "./me2.jpg";
import mycv from "../../asset/file/cvSitraka-en.pdf";
import moment from "moment";
function About() {
  const [startDate, setStartDate] = useState(new Date("2019-07-01"));
  const [endDate, setEndDate] = useState(new Date());

  return (
    <>
      <section className="about section" id="about">
        <h2 className="section__title">About Me</h2>
        <span className="section__subtitle">My introduction</span>
        <div className="about__container container grid">
          <img src={me} alt="" className="about__img" loading="lazy" />
          <div className="about__data">
            <p className="about__description">
              Developer of web and mobile applications, having extensive
              knowledge and experience of web technologies.
            </p>
            <div className="about__info">
              <div>
                <span className="about__info-title">
                  {moment.duration(endDate - startDate).years() < 10
                    ? "0" + moment.duration(endDate - startDate).years()
                    : moment.duration(endDate - startDate).years()}
                  +
                </span>
                <span className="about__info-name">
                  Years <br /> experience
                </span>
              </div>
              <div>
                <span className="about__info-title">30+</span>
                <span className="about__info-name">
                  Completed <br /> project
                </span>
              </div>
              {/* <div>
                <span className="about__info-title">01</span>
                <span className="about__info-name">
                  Company <br /> worked
                </span>
              </div> */}
            </div>
            <div className="about__buttons">
              <a download href={mycv} className="button button--flex">
                Download CV
                <i className="uil uil-download-alt button__icon"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
