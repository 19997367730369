import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header'
import Main from './components/main/Main'
import About from './components/about/About'
import Skills from './components/skills/Skills';
import Services from './components/services/Services';
import Qualification from './components/qualification/Qualification';
import Portfolio from './components/portfolio/PortfolioReact';
import Projetc from './components/project/Projetc';
import Testimonial from './components/testimonial/Testimonial';

import Technologies from './components/techno';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';

function App() {

  useEffect(() => {


    /*==================== DARK LIGHT THEME ====================*/
    const themeButton = document.getElementById('theme-button')
    const darkTheme = 'dark-theme'
    const iconTheme = 'uil-sun'

    // Previously selected topic (if user selected)
    const selectedTheme = localStorage.getItem('selected-theme')
    const selectedIcon = localStorage.getItem('selected-icon')

    // We obtain the current theme that the interface has by validating the dark-theme class
    const getCurrentTheme = () => document.body.classList.contains(darkTheme) ? 'dark' : 'light'
    const getCurrentIcon = () => themeButton.classList.contains(iconTheme) ? 'uil-moon' : 'uil-sun'

    // We validate if the user previously chose a topic
    if (selectedTheme) {
      // If the validation is fulfilled, we ask what the issue was to know if we activated or deactivated the dark
      document.body.classList[selectedTheme === 'dark' ? 'add' : 'remove'](darkTheme)
      themeButton.classList[selectedIcon === 'uil-moon' ? 'add' : 'remove'](iconTheme)
    }

    // Activate / deactivate the theme manually with the button
    themeButton.addEventListener('click', () => {
      // Add or remove the dark / icon theme
      document.body.classList.toggle(darkTheme)
      themeButton.classList.toggle(iconTheme)
      // We save the theme and the current icon that the user chose
      localStorage.setItem('selected-theme', getCurrentTheme())
      localStorage.setItem('selected-icon', getCurrentIcon())
    })
    // return () => {
    //   window.removeEventListener('scroll', scrollActive);
    // };

  }, []);
  return (
    <>
      <Header />
      <Main />
      <About />
      <Qualification />
      <Skills />

      {/* <Services /> */}
      <Portfolio />
      <Projetc />
      {/* <Testimonial /> */}
      <Contact />
      <Footer />
      <a href="#" className="scrollup" id="scroll-up">
        <i class="uil uil-arrow-up scrollup__icon"></i>
      </a>
    </>
  );
}

export default App;
