import React, { useEffect } from "react";
import Node from "./img/pngegg.png";
import ReactLogo from "./img/react.png";
import JavaScriptLogo from "./img/javascript.png";
import psLogo from "./img/ps.png";
import lrLogo from "./img/lr.png";
import smf from "./img/smf.png";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        flexDirection: "column",
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        justifyItems: "center",
    },
    imgSkills: {
        objectFit: "contain",
        width: "80%",
    },
}));
function Technologies() {
    const classes = useStyles();


    return (
        <div>
            <section className="technologies section" id="technologies">
                <h2 className="section__title">Technologies</h2>
                <span className="section__subtitle">My technical level</span>
                <div className="container grid">
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={3}>
                            <Paper className={classes.paper}>
                                Symfony
                                <img className={classes.imgSkills} src={smf} alt="" />
                            </Paper>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Paper className={classes.paper}>
                                Javascript
                                <img
                                    className={classes.imgSkills}
                                    src={JavaScriptLogo}
                                    alt=""
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Paper className={classes.paper}>
                                ReactJs
                                <img className={classes.imgSkills} src={ReactLogo} alt="" />
                            </Paper>
                        </Grid>

                        <Grid item xs={6} sm={3}>
                            <Paper className={classes.paper}>
                                NodeJs
                                <img className={classes.imgSkills} src={Node} alt="" />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>

            </section>
        </div>
    );
}

export default Technologies;
